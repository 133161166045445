import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('selectable-orders',{attrs:{"grid-options":_vm.options,"show-select":false,"request-options":{ filter: { 'customer.customer_id': _vm.value.id } },"search-key":"number"},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"no-uppercase",attrs:{"text":"","to":{ name: 'single-order', params: { id: item.number } }}},[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('order-status-chip',{attrs:{"value":item.status}}),(item.customer.guest_id)?_c('order-status-chip',{attrs:{"value":"guest"}}):_vm._e()]}},{key:"item.summary.total_due",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.summary.total_due}})]}}])}),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.canCreate || !_vm.value.id,"small":"","depressed":"","loading":_vm.loadingCreate},on:{"click":_vm.createCart}},[_vm._v(" "+_vm._s(_vm.$t('Create order'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }